import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";
import Home from "../views/Home.vue";
import Page404 from '../views/404.vue'
import OnboardSent from '../views/OnboardSent.vue';
Vue.use(VueRouter);

const routes = [
    {
        path: "/:soft([0-9a-z]+)",
        name: "Home",
        component: Home,
    },
    {
        path: "/onboard-sent",
        name: "onboardSent",
        component: OnboardSent,
    },
    {
        path: '/n/404',
        name: 'Page404',
        component: Page404
    },
    {
        path: '*',
        redirect: '/n/404'
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;
