<template>
	<section class="content-payment-situation">
		<div class="content-payment-situation-itens container">
			<div class="content-text">
				<div class="content-text-image">
					<img class="img-fluid" src="@/assets/img/sucess.png" alt="image sucess" title="image sucess"/>
				</div>
				<div class="content-text-element">
					<h2> O seu registo foi feito.</h2>
					<p> O sistema será ativado em breve após validação.<br/>Esta informação será enviada para o seu email de registo.</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.content-payment-situation {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 30px 0px 100px 0px;
	background: var(--blue-dark-2);
	display: flex;
	align-items: center;
	justify-content: center;
	&-itens {
		.content-text {
			text-align: center;
			&-image {
				margin-bottom: 20px;
				text-align: center;
			}
			&-element {
				margin-bottom: 30px;
				h2 {
					font-size: 40px;
					font-family: 'Font Black';
					color: var(--white);
					margin-bottom: 10px;
					line-height: 1;
				}
				p {
					font-size: 15px;
					font-family: 'Font Roman';
					color: var(--white);
					margin-bottom: 0;
					width: 275px;
					margin: 0 auto;
				}
			}
		}
	}
}
</style>
