<template>
    <div class="onboarding">
        <div class="content-onboarding">
            <div class="content-onboarding-itens container">
                <div class="content-onboarding-itens-header">
                    <div class="content-logo-biip-pay">
                        <img
                            class="img-fluid"
                            src="@/assets/img/on-boarding/biip-pay.jpg"
                            alt="logo biip pay"
                            title="logo biip pay"
                        />
                    </div>
                    <div class="content-others-logos">
                        <img
                            class="img-fluid mr-4"
                            src="@/assets/img/on-boarding/zone-soft.jpg"
                            alt="logo zone soft"
                            title="logo zone soft"
                        />
                        <img
                            class="img-fluid mr-4"
                            src="@/assets/img/on-boarding/paybyrd.jpg"
                            alt="logo paybyrd"
                            title="logo paybyrd"
                        />
                        <img
                            class="img-fluid"
                            src="@/assets/img/on-boarding/visa.jpg"
                            alt="logo visa"
                            title="logo visa"
                        />
                    </div>
                </div>
                <div class="content-onboarding-itens-body">
                    <h2>Registo biip pay</h2>
                    <form
                        class="content-form"
                        id="onboardForm"
                        autocomplete="false"
                        @submit.prevent="sendForm()"
                    >
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="content-form-input">
                                    <label>Nome empresa</label>
                                    <input
                                        type="text"
                                        name="name_company"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.name_company" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.name_company.join(', ') }}</span>
                                    </span>                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="content-form-input">
                                    <label>NIF</label>
                                    <input
                                        type="text"
                                        name="nif"
                                        value=""
                                        placeholder=""
                                        v-mask="'#########'"
                                        required
                                    />
                                    <span v-if="errors.nif" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.nif.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="content-form-input">
                                    <label>Nome</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.name" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.name.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>E-mail</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.email" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.email.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="false">
                                <div class="content-form-input">
                                    <label>DDI</label>
                                    <select
                                        name="country_code"
                                        value=""
                                        required
                                        class="form-control"
                                        style=""
                                    >
                                        <option value="">-- escolha --</option>
                                        <option value="351">Portugal (+351)</option>
                                    </select>
                                    <span v-if="errors.country_code" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.country_code.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>Telefone</label>                                    
                                        <VuePhoneNumberInput
                                            id="telphone"   
                                            name="telphone-input"   
                                            v-model="phoneCountry.numbervisible"   
                                            :translations ="phoneCountry.translations"
                                            :default-country-code="phoneCountry.defaultCountryCode"
                                            :error="phoneCountry.hasError"                                            
                                            clearable          
                                            @update="onPhoneUpdate"
                                            @phone-number-blur="onPhoneValidate"
                                        />
                                    <span v-if="errors.telphone" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.telphone.join(', ') }}</span>
                                    </span>
                                    <span v-if="errors.country_code" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.country_code.join(', ') }}</span>
                                    </span>                                                                           
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="content-form-input">
                                    <label>Rua</label>
                                    <input
                                        type="text"
                                        name="street"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.street" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.street.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="content-form-input">
                                    <label>Número</label>
                                    <input
                                        type="text"
                                        name="number"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.number" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.number.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>Complemento</label>
                                    <input
                                        type="text"
                                        name="complement"
                                        value=""
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>Código Postal</label>
                                    <input
                                        type="text"
                                        name="postal_code"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.postal_code" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.postal_code.join(', ') }}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>País</label>
                                    <select class="form-control" name="country" required>
                                      <option value=""> -- escolha -- </option>
                                      <option v-for="(country, k) in listCountry" :value="country.code" :key="k">{{ country.name }}</option>
                                    </select>
                                    <span v-if="errors.country" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.country.join(', ')}}</span>
                                    </span>                                      
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>Cidade</label>
                                    <input
                                        type="text"
                                        name="city"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.city" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.city.join(', ') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>IBAN</label>
                                    <input
                                        type="text"
                                        name="iban"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.iban" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.iban.join(', ') }}</span>
                                    </span>                                    
                                </div>
                            </div>

                            <template v-if="need_iban_info">
                            <input type="hidden" name="has_iban_info" value="1">
                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>BIC/SWIFT</label>
                                    <input
                                        type="text"
                                        name="bic_swift"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.bic_swift" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{errors.bic_swift.join(', ')}}</span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>Banco</label>
                                    <input
                                        type="text"
                                        name="bank_name"
                                        value=""
                                        placeholder=""
                                        required
                                    />
                                    <span v-if="errors.bank_name" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{errors.bank_name.join(', ')}}</span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>País do Banco</label>
                                    <select class="form-control" name="bank_country" required>
                                      <option value=""> -- escolha -- </option>
                                      <option v-for="(country, k) in listCountry" :value="country.code" :key="k">{{ country.name }}</option>
                                    </select>
                                    <span v-if="errors.bank_country" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.bank_country.join(', ')}}</span>
                                    </span>                                      
                                </div>
                            </div>                                                      
                            </template>

                            <div class="col-lg-6">
                                <div class="content-form-input">
                                    <label>Volume de Faturação</label>
                                    <select class="form-control" name="value_company" required>
                                      <option value=""> -- escolha -- </option>
                                      <option v-for="(item, k) in listValueCompany" :value="item.value" :key="k">{{item.label }}</option>
                                    </select>
                                    <span v-if="errors.value_company" class="content-form-input-error">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                class=""
                                            ></path>
                                        </svg>
                                        <span>{{ errors.value_company.join(', ')}}</span>
                                    </span>                                      
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="content-form-input-check">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        value="1"
                                        placeholder=""
                                        @change="changeTerms($event)"
                                        required
                                    />
                                    <label>Li e aceito os
                                        <a
                                            @click="
                                                $bvModal.show(
                                                    'modal-terms-conditions'
                                                )
                                            "
                                            >termos e condições.</a
                                        ></label
                                    >
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <button
                                    class="btn-submit"
                                    type="submit"
                                    v-if="accepted_terms"
                                >
                                    Submeter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <modal-terms />
    </div>
</template>

<script>
import ModalTerms from "@/components/modals/ModalTerms.vue";
import axios from "axios";
import {mask} from 'vue-the-mask'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: "OnBoarding",
    data() {
        return {
            ci: "",
            need_iban_info: true,
            accepted_terms: false,
            listCountry: [],
            listValueCompany:[],
            errors:{},
            phoneCountry: {
                numbervisible: null,
                number:null,
                ddi: null,
                translations:{
                    countrySelectorLabel: "Escolha o país",                    
                    countrySelectorError: 'Indique o pais',
                    phoneNumberLabel: 'Telemóvel',
                    example: 'Exemplo :'
                },
                defaultCountryCode:'PT',
                hasError: false              
            },

        };
    },
    mounted() {
        this.fetchQueryUrl();
        this.fetchCountries();
        this.fetchValuations();
    },
    components: {
        ModalTerms,
        VuePhoneNumberInput
    },
    methods: {
        // recupera listagem de paises
        fetchCountries() {
          let form = new FormData();
          //sinaliza a linguagem que os paises seram mostrados
          form.append('lang', 'pt');
          // solicita a lista de paises e codigo alpha3
          this.$root.request('onboard::onListCountries', form)
          .then(resp => {
            this.listCountry = resp.list;
          })
        },

        // recupera listagem de valuations
        fetchValuations() {
          let form = new FormData();                    
          // solicita a lista de paises e codigo alpha3
          this.$root.request('onboard::onListValuation', form)
          .then(resp => {
            this.listValueCompany = resp.list;
          })
        }, 

        // recupera o code identifier da empresa
        fetchQueryUrl() {
            let query = this.$route.query;
            if (query.ci) {
                this.ci = query.ci;
            }
        },
        changeTerms(el) {
            this.accepted_terms = el.target.checked ? true : false;
        },
        sendForm() {
            let form = document.querySelector("#onboardForm");
            if (form.checkValidity()) {
                let data = new FormData(form);
                data.append("soft", this.$route.params.soft);
                data.append("ci", this.ci);
                data.append("telphone", this.phoneCountry.number);
                data.append("country_code", this.phoneCountry.ddi);
                this.$root.request("onboard::onFetch", data).then((resp) => {
                    if(true === resp.success){
                      this.$router.push({ path: "/onboard-sent" });
                    }else{
                      let msg = resp.errors;                      
                      this.errors = msg;
                    }                    
                });
            } else {
                form.reportValidity();
            }
        },

        // international phone validation methods
        onPhoneUpdate( payload ){
            this.phoneCountry.hasError = !payload.isValid;
            this.phoneCountry.ddi = payload.countryCallingCode;            
            this.phoneCountry.number = payload.isValid ? payload.nationalNumber : undefined;            
        },
        onPhoneValidate(){},
        fetchPhoneInfo( key ){            
            if (false === this.phoneCountry.hasError && this.phoneCountry[key]){
                return this.phoneCountry[key];
            } 
            return null;
        }
    },
    directives: {mask}
};
</script>
<style lang="scss">
.content-onboarding {
    padding: 50px 0px;
    @media (max-width: 767px) {
        padding: 30px 0px;
    }
    &-itens {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            @media (max-width: 767px) {
                flex-direction: column;
                .content-logo-biip-pay {
                    margin-bottom: 20px;
                }
            }
        }
        &-body {
            h2 {
                font-size: 32px;
                font-family: "Font Black";
                color: #180b29;
                margin-bottom: 27px;
                text-align: left;
                @media (max-width: 991px) {
                    font-size: 24px;
                }
            }
            .content-form {
                &-input {
                    margin-bottom: 20px;
                    label {
                        display: block;
                        font-size: 18px;
                        font-family: "Font Roman";
                        color: rgba(24, 11, 41, 0.6);
                        text-align: left;
                        margin-bottom: 8px;
                        @media (max-width: 767px) {
                            font-size: 14px;
                            margin-bottom: 4px;
                        }
                    }
                    input {
                        width: 100%;
                        height: 40px;
                        border: 1px solid #dfdfdf;
                        border-radius: 10px;
                        outline: none;
                        font-size: 18px;
                        font-family: "Font Medium";
                        color: #180b29;
                        padding-left: 15px;
                        @media (max-width: 767px) {
                            height: 35px;
                            border-radius: 5px;
                            font-size: 14px;
                        }
                    }
                    &-check {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        & > input[type="checkbox"]:checked:before {
                            content: "";
                            height: 100%;
                            width: 100%;
                            background: url("~@/assets/img/check.png");
                            background-position: center !important;
                            background-repeat: no-repeat !important;
                            background-color: var(--white);
                        }
                        & > input[type="checkbox"]:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: var(--white);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 10px;
                            border: 0;
                            @media (max-width: 767px) {
                                border-radius: 5px;
                            }
                        }
                        & > input[type="checkbox"] {
                            position: relative;
                            width: 40px;
                            height: 40px;
                            border: 1px solid #dfdfdf;
                            border-radius: 10px;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            outline: 0 !important;
                            margin: 0;
                            @media (max-width: 767px) {
                                width: 25px;
                                height: 25px;
                                border-radius: 5px;
                            }
                        }
                        label {
                            font-size: 18px;
                            font-family: "Font Roman";
                            color: rgba(24, 11, 41, 0.6);
                            padding-top: 3px;
                            margin-left: 20px;
                            margin-bottom: 0;
                            @media (max-width: 767px) {
                                font-size: 14px;
                                margin-left: 15px;
                            }
                            a {
                                color: rgba(24, 11, 41, 0.6) !important;
                                text-decoration: underline !important;
                                text-decoration-color: rgba(
                                    24,
                                    11,
                                    41,
                                    0.6
                                ) !important;
                                transition: 500ms;
                                &:hover,
                                &:focus {
                                    cursor: pointer;
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                    &-error {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-family: "Font Light";
                        color: #e62f2c;
                        margin-top: 5px;
                        svg {
                            fill: #e62f2c;
                            width: 15px;
                            height: 20px;
                            margin-right: 5px;
                        }
                    }
                }
                .btn-submit {
                    width: 156px;
                    height: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #e84e0f;
                    border: 1px solid #e84e0f;
                    border-radius: 32px;
                    font-size: 20px;
                    font-family: "Font Medium";
                    color: #fff;
                    margin-top: 40px;
                    transition: 500ms;
                    &:hover,
                    &:focus {
                        cursor: pointer;
                        background: #fff;
                        color: #e84e0f;
                    }
                }
            }
        }
    }
}
</style>
