<template>
    <!-- style="--main-color: initial" -->
    <div id="app">		
        <main class="content-main">
            <router-view />
        </main>

        <!--- BACKDROP -->
        <div class="backdrop_bar d-none ok"></div>
        <!-------------------->        		
    </div>
</template>

<style lang="scss">
  @import "./assets/scss/generic/mixins.scss";
  @import "./assets/scss/generic/fonts.scss";
  @import "./assets/scss/generic/utilities.scss";

  :root {
      --main-color: #cc0000;
      --main-color-light: #f21a35;
      --main-color2: #d9b48f;
      --main-color3: #c6afa0;
      --main-color4: #5e4f47;
      --red: #cc0000;
      --red-2: #f21a35;
      --green: #4ab900;
      --white: #fff;
      --black: #000;
      --blue: #19d1cd;
      --blue-dark: #132148;
      --blue-dark-2: #152147;
  }
  html,
  body {
      margin: 0;
      width: 100%;
      color: #687188;
  }
  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
  }
  #nav {
      padding: 30px;
      a {
          font-weight: bold;
          color: #2c3e50;

          &.router-link-exact-active {
              color: #42b983;
          }
      }
  }
  .swiper-button-next,
  .swiper-button-prev {
      outline: none !important;
      &::after {
          content: none;
      }
  }
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-button-prev {
      background: 0;
      border: 0;
  }
  .overflow-hidden {
      overflow: hidden;
  }
  .backdrop_bar {
      position: fixed;
      z-index: 2;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
  }
  a {
      text-decoration: none !important;
  }
  button {
    outline: none !important;
  }
</style>
<script>
export default {	
    data() {}
};
</script>
